import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { ConfigurationContext } from '../../context';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    order: ${props => props.order};
    >.inner{
        position: relative;
        display: flex;
        .basic-multi-select{
            width: 100%;
            .select__control{
                min-height: 42px;
                border-color: rgb(186, 186, 194)!important;
            }
        }
        >.icon{
            top: 11px;
            left: 10px;
            font-size: 23px;
            position: absolute;
            color: #645FF9;
            
        }
    }
    small{
        color: #484D63;
        margin-top: 5px;
    }
    .label{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5px;
        min-height: 20px;
        .label_icon{
            display: flex;
            margin-right: 5px;
            height: 20px;
            min-width: 20px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            svg{
                float: left;
                height: 100%;
                fill: ${props => `rgb(${props.pancolors.black_overwhite})`};  
            }
        }
        label{
            font-size: 16px;
            line-height: 16px;
            color: #484D63;
            &.isRequired{
                :after{
                    content: " *";
                }
            }
        }
        
    }
    &.error{
        select{
            border: 2px solid ${props => `rgb(${props.pancolors.red})`};
        }
    }
`

const SelectMultiCustom = (props) => {

    const { t } = useTranslation();
    
    const { panColors } = useContext(ConfigurationContext);

    const { order,
            name,
            label,
            onChange,
            className,
            nulledOption = true,
            value,
            help,
            placeholder,
            icon,
            label_icon = null,
            styles = null,
            isRequired = false,
            data = null,
            disabled = false,
            index = null } = props;
        
    const [ normalizeData, setNormalizeData ] = useState(null);
    
    useEffect(() => {
        console.log("data", data)
        if(data){
            
            let nData = null;
            if(typeof data[0] == "string"){
                nData = data.map(d => {
                    return {value: d, label: d}
                    //return {value: d, description: d}
                })
            }
            if(typeof data[0] == "object"){
                nData = data.map(d => {
                    return {value: d._id, label: d.name}
                    //return {value: d._id, description: d.name}
                })
            }
            setNormalizeData(nData);
            
        }
    
      return () => {}
    }, [data])

    const _onChange = (e) => {

    }
    
    useEffect(() => {
      console.log("normalizeData", normalizeData)
    
      return () => {}
    }, [normalizeData])
    

    return (
        <Wrapper 
            pancolors={panColors} 
            styles={styles}
            className={className} 
            isRequired={isRequired}
            icon={icon ? true : false}
            order={order}
        >

            { label ? 
                <div className="label">
                    {label_icon && <div className="label_icon">{label_icon}</div>}
                    <label htmlFor={name} className={isRequired ? "isRequired" : ""}>{label}</label> 
                </div>
            : "" }

            <div className="inner">

                <Select
                    //defaultValue={[/*normalizeData[2], normalizeData[3] */]}
                    //isMulti
                    name="colors"
                    options={normalizeData}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Seleccione una opción..."
                    onChange={(e) => onChange(name, e.value, "")} 
                    isDisabled={disabled}
                />

            </div> 

            { help ? <small>{help}</small> : "" }

        </Wrapper>

    )

}

export default SelectMultiCustom;