import React, { Fragment, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Moderate from '../../Benefits/Moderate';
import { IoMdRefresh } from "react-icons/io";

import { ConfigurationContext } from '../../../context';

import { useNavigate } from 'react-router-dom';
import ModerateElement from "../Dialogs/ModerateElement";
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';
import { useTranslation } from 'react-i18next';

dayjs.extend(utc);
dayjs.extend(timezone);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

  const { onSelectAllClick, 
          order, 
          orderBy, 
          numSelected, 
          rowCount, 
          onRequestSort, 
          headCells,
          filters } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {filters.status !== "all" && filters.status !== "2" && filters.status !== "-1" ?
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell> : ""
        }
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sorteable ? 
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            :
            headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


const WrapperStyled = styled.div`

  color: ${props => `rgb(${props.pancolors.black_overwhite})`};

  
  .create_cell{
    width: 160px;
  }
  .points_cell{
    width: 12vw;
  }
  .code_cell{
    width: 70px;
  }
  .user_name_cell{
    width: 15vw;
  }
  .name_cell{
    width: 15vw;
  }
  .moderate_cell{

  }
  .create_cell{
    
  }
  .actions_cell{
    width: 120px;
  }
  .inner_avatar{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
  }
  .icon{
      fill: #FFFFFF!important;
  }
  .MuiToolbar-root{
      padding-left: 15px;
  }
  .MuiTableRow-root{
      &.Mui-selected{
          background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
          :hover{
              background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
          }
      }
      &.MuiTableRow-hover{
          :hover{
              background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
          }
      }
  }

  .MuiTableCell-body{
    color: ${props => `rgb(${props.pancolors.black_overwhite})`}!important;
  }

  .bt_bulk_delete{

  }
  .bt_bulk_activate{
      margin-left: 10px;
  }
  .bt_bulk_desactivate{
      margin-left: 10px;
  }
  .Mui-checked{
      color: ${props => `rgba(${props.pancolors.dots}, 1)`}!important;
  }
  .MuiCheckbox-indeterminate{
      color: ${props => `rgba(${props.pancolors.dots}, 1)`}!important;
  }
    
  .actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .bt_delete_single,
    .bt_edit_single{
      display: flex;
      font-size: 20px;
      color: ${props => `rgb(${props.pancolors.red})`};
      cursor: pointer;
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
      }
    }
    .bt_edit_single{
      margin-right: 10px;
      color: ${props => `rgb(${props.pancolors.dots})`};
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
      }
    }
    .bt_view_single{
      font-size: 15px;
      line-height: 15px;
      font-weight: 600;
      color: ${props => `rgba(${props.pancolors.dots}, 1)`}!important;
      cursor: pointer;
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`}!important;
      }
    }
  }
  .box_modules{
    display: flex;
    flex-direction: row;
    .modules_count{
      font-size: 16px;
      font-weight: 600;
      color: ${props => `rgb(${props.pancolors.dark_gray})`};
    }
    .have_modules{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .bt_create_module{
      .text{
        margin: 0;
      }
    }
    //
    .bt{
      padding: 0;
    }
  }
  .box_evaluation{
    display: flex;
    flex-direction: row;
    .bt_view_evaluation{
      padding: 0;
      p{
        margin: 0;
      }
    }
  }
  /* .cancelado{
      color: #FFFFFF;
      background-color: ${props => `rgb(${props.pancolors.red})`};
  }
  .aceptado{
      color: #FFFFFF;
      background-color: ${props => `rgb(${props.pancolors.yellow})`};
  }
  .entregado{
      color: #FFFFFF;
      background-color: ${props => `rgb(${props.pancolors.green})`};
  } */
  .pending{
    font-weight: 600;
    color: ${props => `rgb(${props.pancolors.dark_gray})`}!important;
  } 
  .approved{
    font-weight: 600;
    color: ${props => `rgb(${props.pancolors.green})`}!important;
  } 
  .revision{
    font-weight: 600;
    color: ${props => `rgb(${props.pancolors.yellow})`}!important;
  } 
  .rejected{
    font-weight: 600;
    color: ${props => `rgb(${props.pancolors.red})`}!important;
  }
`

const ToolbarStyled = styled(Toolbar)`
    
  &.MuiToolbar-root{
    background-color: ${props => `${props.numselected ? "rgba("+props.pancolors.dots+", 0.03)" : "transparent"}` }!important;
  }
  .bt_bulk_status{
    .icon{
      display: flex;
      margin: 0;
      font-size: 25px;
    }
  }

`

function EnhancedTableToolbar(props) {

    const { numSelected,
            title,
            panColors,
            selected,
            setSelected,
            setRows,
            getData,
            page,
            rowsPerPage,
            filters } = props;
    
    const [ statusToChange, setStatusToChange ] = useState("");
    const [ isModerated, setIsModerated ] = useState(false);
    const [ moderateElementOpen, setModerateElementOpen ] = useState(false);
    const [ titleModerateText, setTitleModerateText ] = useState("");
    const [ elementToModerate, setElementToModerate ] = useState("");
    
    const handleModerateRows = (status, status_id) => {
      setStatusToChange(status_id);
      const id_list = selected.map((id, index) => {
        return {_id: id};
      })
      setElementToModerate(JSON.stringify(id_list));
      setTitleModerateText(`<span class="bolder">¿Confirmar que desea cambiar al estado "${status}" ${selected.length > 1 ? "a los "+ selected.length +" canjes seleccionados" : " al canje seleccionado"}?</span>`);
      setModerateElementOpen(true);
    }
    
    useEffect(() => {
      
      if(isModerated){
        setRows([]);
        getData(page, rowsPerPage, filters);
        setElementToModerate(null);
        setTitleModerateText('');
        setModerateElementOpen(false);
        setIsModerated(false);
        setSelected([]);
      }
    
      return () => {}
    }, [isModerated])
    

  return (
    <ToolbarStyled
        pancolors={panColors}
        numselected={numSelected}
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      {numSelected > 0 ? (
        <></>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          { title }
        </Typography>
      )}
      
      <ModerateElement 
        open={moderateElementOpen} 
        setOpen={setModerateElementOpen} 
        title={titleModerateText}
        setTitle={setTitleModerateText}
        id={elementToModerate}
        scope="moderate"
        isModerated={isModerated}
        setIsModerated={setIsModerated}
        statusToChange={statusToChange}
        setStatusToChange={setStatusToChange}
      />

      {numSelected > 0 ? (
        <>
        {filters.status === '0' &&
          <>
            <ButtonTextCustom 
                className="bt_bulk_status"
                text="Aceptado"
                onClick={() => handleModerateRows("Aceptado", 1)}

                icon={<IoMdRefresh />}
                background={{ normal: panColors.primary, hover: panColors.primary }}
                border={{ normal: panColors.primary, hover: panColors.primary }}
                textcolor={{ normal: panColors.primary, hover: panColors.primary }}
            />
            <ButtonTextCustom 
                className="bt_bulk_status"
                text="Cancelado"
                onClick={() => handleModerateRows("Cancelado", -1)}

                icon={<IoMdRefresh />}
                background={{ normal: panColors.primary, hover: panColors.primary }}
                border={{ normal: panColors.primary, hover: panColors.primary }}
                textcolor={{ normal: panColors.primary, hover: panColors.primary }}
            />
          </>
        }

        {filters.status === '1' &&
          <>
            <ButtonTextCustom 
                className="bt_bulk_status"
                text="Entregado"
                onClick={() => handleModerateRows("Entregado", 2)}

                icon={<IoMdRefresh />}
                background={{ normal: panColors.primary, hover: panColors.primary }}
                border={{ normal: panColors.primary, hover: panColors.primary }}
                textcolor={{ normal: panColors.primary, hover: panColors.primary }}
            />
            <ButtonTextCustom 
                className="bt_bulk_status"
                text="Cancelado"
                onClick={() => handleModerateRows("Cancelado", -1)}

                icon={<IoMdRefresh />}
                background={{ normal: panColors.primary, hover: panColors.primary }}
                border={{ normal: panColors.primary, hover: panColors.primary }}
                textcolor={{ normal: panColors.primary, hover: panColors.primary }}
            />
          </>
        }
        </>
      ) : (
        <></>
        
      )}
    </ToolbarStyled>
  );
}


const DataTable = (props) => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { headCells,
          title,
          data,
          getData,
          totalRows,
          filters } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [ moderateElementOpen, setModerateElementOpen ] = useState(false);
  const [ titleModerateText, setTitleModerateText ] = useState("");
  const [ elementToModerate, setElementToModerate ] = useState("");
  const [ isModerated, setIsModerated ] = useState(false);
  const [ isDisabled, setIsDisabled ] = useState([]);
  
  const { colors,
          panColors } = useContext(ConfigurationContext);

    useEffect(() => {
        
      if(data === null){
        getData(0, rowsPerPage, filters);
      }
      
      if(data && data.length){
        setRows(data);
      }
      return () => {}

    }, [data])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => {
        if(filters.status === '0'){
          if(n.status === 0){
            return n._id;
          }
        }
        if(filters.status === '1'){
          if(n.status === 1){
            return n._id;
          }
        }
      });
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage, rowsPerPage, filters);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    getData(0, parseInt(event.target.value, 10), filters);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModerate = (id, fullname) => {

    setElementToModerate(JSON.stringify([{_id: id}]));
    setTitleModerateText(`<span class="bolder">¿Confirmar Eliminar el curso</span> ${fullname}<span class="bolder">?</span>`);
    setModerateElementOpen(true);

  }

  useEffect(() => {
    
    if(isModerated){
      setRows([]);
      getData(page, rowsPerPage, filters);
      setElementToModerate(null);
      setTitleModerateText('');
      setModerateElementOpen(false);
      setIsModerated(false);
    }
  
    return () => {}
  }, [isModerated])

  const isSelected = (name) => selected.indexOf(name) !== -1;
  
  const handleStatusChanged = (_id, newStatus) => {
    if(filters.status !== 'all'){
      setIsModerated(true);
    }
  }

  if(data === null){
    return null;
  }
  

  return (
    <WrapperStyled pancolors={panColors}>

        <ModerateElement 
          open={moderateElementOpen} 
          setOpen={setModerateElementOpen} 
          title={titleModerateText}
          setTitle={setTitleModerateText}
          id={elementToModerate}
          scope="moderate"
          isModerated={isModerated}
          setIsModerated={setIsModerated}
        />

        <EnhancedTableToolbar 
            numSelected={selected.length} 
            selected={selected}
            setSelected={setSelected}
            title={title}
            colors={colors}
            panColors={panColors}
            setRows={setRows}
            getData={getData}
            page={page}
            rowsPerPage={rowsPerPage}
            filters={filters}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              filters={filters}
            />
            <TableBody>
              { stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  let status = "";
                  let status_class = "";

                  switch (row.status) {
                    case 0:
                      status = t("texts.Pendiente");
                      status_class = "pending";
                      break;
                    case 1:
                      status = t("texts.Aprobado");
                      status_class = "approved";
                      break;
                    case 2:
                      status = t("texts.En revision");
                      status_class = "revision";
                      break;
                    case 3:
                      status = t("texts.Rechazado");
                      status_class = "rejected";
                      break;
                    default:
                      break;
                  }
                  
                    return (
                        <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row._id}
                            selected={isItemSelected}
                        >
                          {filters.status != "all" && filters.status !== "2" && filters.status != "-1" ?
                            <TableCell padding="checkbox">
                                <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    disabled={(isDisabled.filter(el => el === row._id)).length ? true : false}
                                    inputProps={{ 'aria-labelledby': labelId, }}
                                />
                            </TableCell> : "" }

                            {/* <TableCell className='code_cell' align="left">{row.code ? row.code : "- - -"}</TableCell> */}
                            <TableCell className='name_cell' align="left">{row.name_user ? row.name_user : "- - -"}</TableCell>
                            {/* <TableCell className='user_name_cell' align="left">{row.name_store ? row.name_store : "- - -"}</TableCell> */}
                            <TableCell className='user_name_cell' align="left">{row.user_login ? row.user_login : "- - -"}</TableCell>
                            
                            <TableCell className='points_cell' align="left">{row.name_activity ? row.name_activity : "- - -"}</TableCell>
                            {/* <TableCell className={`moderate_cell ${status_class}`} align="left">
                              {status ? status : "- - -"}
                              <Moderate redeem_id={row._id} actual_status={row.status} handleStatusChanged={handleStatusChanged} />
                            </TableCell> */}
                            <TableCell className='moderate_cell' align="left">{row.area ? row.area : "- - -"}</TableCell>
                            <TableCell className='create_cell' align="left">{row.date_init ? dayjs(row.date_init).tz(dayjs.tz.guess()).format('HH:mm:ss - DD/MM/YYYY') : "- - -"}</TableCell>
                            <TableCell className='create_cell' align="right">
                              <div className='actions'>
                                <div className='bt_view_single' onClick={() => navigate(`/stores/campaigns/audits/${row._id}`)}>{ t("texts.ver mas") }</div>
                              </div>
                            </TableCell>
                        </TableRow>
                    );
                  
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      
    </WrapperStyled>
  );
}

export default DataTable;