import React, { useState, useEffect, useCallback, useContext } from 'react';
import { path } from 'ramda';
import axios from 'axios';
import connect from '../helpers/connect';
import { ConfigurationContext } from './ConfigurationContext';

export const ContentContext = React.createContext({});

export const ContentProvider = ({ children }) => {

    const { setHaveResponseError,
            setHaveResponseErrorMessage } = useContext(ConfigurationContext);
    
    const [ users, setUsers ] = useState(null);
    const [ user, setUser ] = useState(null);
    const [ stores, setStores ] = useState(null);
    const [ store, setStore ] = useState(null);
    const [ courses, setCourses ] = useState(null);
    const [ course, setCourse ] = useState(null);
    const [ benefits, setBenefits ] = useState(null);
    const [ benefit, setBenefit ] = useState(null);
    const [ benefitRedeems, setBenefitRedeems ] = useState(null);
    const [ product, setProduct ] = useState(null);
    const [ products, setProducts ] = useState(null);
    const [ assessment, setAssessment ] = useState(null);
    const [ module, setModule ] = useState(null);
    const [ post, setPost ] = useState(null);
    const [ posts, setPosts ] = useState(null);
    const [ idModuleSelected, setIdModuleSelected ] = useState(null);
    const [ idCourseSelected, setIdCourseSelected ] = useState(null);
    const [ ecommerceCategories, setEcommerceCategories ] = useState(null);
    const [ surverList, setSurverList ] = useState(null);
    const [ posList, setPosList ] = useState(null);
    const [ surveys, setSurveys ] = useState(null);
    const [ survey, setSurvey ] = useState(null);
    
    const [ devices, setDevices ] = useState(null);
    const [ basicData, setBasicData ] = useState(null);
    const [ audits, setAudits ] = useState(null);
    const [ audit, setAudit ] = useState(null);
    const [ postsComments, setPostsComments ] = useState(null);
    const [ menuData, setMenuData ] = useState(null);
    
    useEffect(() => {
        if(localStorage.getItem('access_token')){
            if(!basicData){
                getFormsData();
            }
        }

      return () => {}

    }, [localStorage.getItem('access_token')])
    
    const testEndpoint = useCallback(async () => {
        const token = localStorage.getItem('access_token');
        const requestURL = `${process.env.REACT_APP_API_SERVER}/oneapp/utils/checkBucketName`;

        connect.get(`${requestURL}`).then(result => { 

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const getUsers = useCallback(async (page = 1, rowPerPage = 25, filters, returnPromise = false) => {
        console.log("filters: ", filters)
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_users`;

        let statuses = "";
        if(parseInt(filters.status) === -2){
            statuses = JSON.stringify([{"$or":[{"status" : -2}]}]);
        }else{
            if(parseInt(filters.status) === -1){
                statuses = JSON.stringify([{"$or":[{"status" : -1}]}]);
            }else{
                statuses = JSON.stringify([{"$or":[{"status" : 0} , {"status" : 1} , {"status" : -3}]}]);
            }
        }

        if(!returnPromise){
            connect.get(`${requestURL}`, {
                headers: {
                    'page': page,
                    'size': rowPerPage,
                    'filter': statuses,//parseInt(filters.status) === -2 ? JSON.stringify([{"$or":[{"status" : -2}]}]) : JSON.stringify([{"$or":[{"status" : 0} , {"status" : 1} , {"status" : -3}]}]),
                    'alphabetical_order': parseInt(filters.alphabetical_order),
                    'date_order': parseInt(filters.date_order),
                    'area': filters.area,
                    'role': filters.role,
                    'region': filters.region,
                    'text_filter': filters.text_filter
                },
            }).then(res => {
                
                setUsers(res.data.single);

            }).catch(err => {

                setHaveResponseError(true);
                setHaveResponseErrorMessage(err.message);

            });
        }else{

            const result = connect.get(`${requestURL}`, {
                headers: {
                    'page': page,
                    'size': rowPerPage,
                    'filter': parseInt(filters.status) === -2 ? JSON.stringify([{"$or":[{"status" : -2}]}]) : JSON.stringify([{"$or":[{"status" : 0} , {"status" : 1}]}]),
                    'alphabetical_order': parseInt(filters.alphabetical_order),
                    'date_order': parseInt(filters.date_order),
                    'area': filters.area,
                    'role': filters.role,
                    'region': filters.region,
                    'text_filter': filters.text_filter
                },
            })

            return result;

        }

	}, []);
    
    const getUser = useCallback(async (id) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_user`;
        connect.get(`${requestURL}`, {
            headers: {
                'id_user': id,
            },
        }).then(res => {
            
            setUser(path(['data', 'single', 'user'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);
    
    const editImageUser = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_user`;

        const params = new URLSearchParams()
              params.append('user_id', payload.user_id);
              params.append('avatar', payload.avatar);

        const result = await connect.post(requestURL, params);

        return result;

    }, []);

    const createUser = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/created_user`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const createCourse = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/course_new`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);
    

    const editCourse = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/course_edit`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };
    
    const statusCourse = async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/updated_status_courses`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };
    
    
    const editModule = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/content`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

	}, []);

    const getModule = useCallback(async (id) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_content`;
        connect.get(`${requestURL}`, {
            headers: {
                'id_content': id,
            },
        }).then(res => {
            
            setModule(path(['data', 'single', 'content'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const createModule = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/content_new`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const statusModule = async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/updated_status_content`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    }
    const statusPost = async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/updated_status_post`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    }

    const editUserProfile = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_user_profile`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    }
    
    const statusUser = async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/updated_status_users`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    }
    const editUser = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_user`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };

    const deleteUser = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_user`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };
    
    const getUserDevices = useCallback(async (skip = 0, limit = 20) => {

        const requestURL = `https://dummyjson.com/posts?skip=${skip}&limit=${limit}`;

        await axios.get(`${requestURL}`).then(result => { 
            setDevices(result.data);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const getStores = useCallback(async (page = 1, rowPerPage = 25, filters, returnPromise = false) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_stores`;
        if(!returnPromise){
            connect.get(`${requestURL}`, {
                headers: {
                    'page': page,
                    'size': rowPerPage,
                    'filter': parseInt(filters.status) === -2 ? JSON.stringify([{"$or":[{"status" : -2}]}]) : JSON.stringify([{"$or":[{"status" : 0} , {"status" : 1}]}]),
                    'cluster': filters.cluster,
                    'region': filters.region,
                    'text_filter': filters.text_filter
                },
            }).then(res => {
                
                setStores(path(['data', 'single'], res));

            }).catch(err => {

                setHaveResponseError(true);
                setHaveResponseErrorMessage(err.message);

            });
        }else{

            const result = connect.get(`${requestURL}`, {
                headers: {
                    'page': page,
                    'size': rowPerPage,
                    'filter': parseInt(filters.status) === -2 ? JSON.stringify([{"$or":[{"status" : -2}]}]) : JSON.stringify([{"$or":[{"status" : 0} , {"status" : 1}]}]),
                    'cluster': filters.cluster,
                    'region': filters.region,
                    'text_filter': filters.text_filter
                },
            })

            return result;

        }

	}, []);

    const getStore = useCallback(async (id) => {


        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_store`;
        connect.get(`${requestURL}`, {
            headers: {
                'store_id': id,
            },
        }).then(res => {
            
            setStore(path(['data', 'single', 'store'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);
    
    const createStore = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/create_store`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const editStore = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_store`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };

    const statusStores = async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/updated_status_store`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    }

      const getByPassImageUploadRoute = async (payload, content_type = null) => {
        window.Buffer = window.Buffer || require("buffer").Buffer;


            const params = new URLSearchParams()
                params.append('image_name', payload.name)
                params.append('path', payload?.path ? payload?.path : 'profile/avatar/')

            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'//!content_type ? 'application/x-www-form-urlencoded' : content_type
                }
            }

            const response = await connect.post(`${process.env.REACT_APP_BASE_URL}/onepanel/generateUploadURLCognito`, params, config);
            const imageProfileUploadURL = path(['data', 'uploadURL',], response) || [];//path(['data', 'single', 'uploadURL',], response) || [];
            return imageProfileUploadURL;
    

    
      }
      

      const uploadRawFile = useCallback(async (file, imageProfileUploadURL) => {

        var data = new FormData();
          data.append('foo', 'bar');
          data.append('file', file);

        var config = {
            onUploadProgress: function(progressEvent) {
            }
        };

        axios.put(imageProfileUploadURL, data, config)
            .then(function (res) {
            })
            .catch(function (err) {
            });
        

        }, []);



      const uploadFile = async (_file, imageProfileUploadURL) => {

        window.Buffer = window.Buffer || require("buffer").Buffer;
            
            const type = _file.split(';')[0].split('/')[1];
            const buffer = Buffer.from(_file.replace(/^data:image\/\w+;base64,/, ""),'base64');
            const result = await axios.put(imageProfileUploadURL, buffer, {
                headers: {
                    'Content-Type': 'image/jpeg',
                    'Content-Encoding': 'base64'
                },
            });
            const imageProfileURL = imageProfileUploadURL.split('?')[0];

            return imageProfileURL;
    
      }

    const getFilterData = useCallback(async (section) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_data_filter`;
        const result = connect.get(`${requestURL}`, {
            headers: {
                'section': section,
            },
        }).then(res => {
            
            return path(['data', 'single'], res);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

        return result;

	}, []);

    const getFormsData = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/utils/get_data`;
        
        const res = await connect.get(`${requestURL}`);
            /*
            const valoresUTC_arr = [{_id: "America/Buenos_Aires", name: "America/Buenos_Aires", utc: "UTC -3 (Argentina)"},
                                    {_id: "America/Mexico_City", name: "America/Mexico_City", utc: "UTC -3 (México)"}];
            */
            let valoresUTC_arr = [];
            let time_zone = path(['data', 'single', 'time_zone'], res) ? path(['data', 'single', 'time_zone'], res) : [];
            if(time_zone){
                valoresUTC_arr = time_zone.map((el, index) => {
                    return {_id: el, name: el}
                })
            }


                                    
            const validate_user_options = [{_id: "phone", name: "Teléfono"},
                                           {_id: "mail", name: "E-Mail"}];

            const link_destination_type = [{_id: "interno", name: "Interno"},
                                           {_id: "externo", name: "Externo"},
                                           {_id: "sincta", name: "Sin CTA"}];
            
            let lenguages_arr = [];
            let lenguages = path(['data', 'single', 'language'], res) ? path(['data', 'single', 'language'], res) : [];
            if(lenguages){


                lenguages_arr = lenguages.map((el, index) => {
                    return {_id: el.prefix, name: el.name, prefix: el.prefix}
                })
            }

            let type_goto_arr = [];
            let type_goto = path(['data', 'single', 'type_goto'], res) ? path(['data', 'single', 'type_goto'], res) : [];
            if(type_goto){
                type_goto_arr = type_goto.map((el, index) => {
                    return {_id: el.name, name: el.title, id_content_required: el.id_content_required}
                })
            }

            let achievements_arr = [];
            let achievements = path(['data', 'single', 'achievements'], res) ? path(['data', 'single', 'achievements'], res) : [];
            if(achievements){
                achievements_arr = achievements.map((el, index) => {
                    return {_id: el._id, name: el.title}
                })
            }   
            
            setMenuData(path(['data', 'single', 'menu', 'options'], res));


            const settings = {
                users: {
                    section_id: "1111",
                    section_index: 1,
                    section_fields: {
                        captain:{
                            label: "¿Es Capitán?",
                            placeholder: "",
                            required: false,
                            visibility: false
                        },
                        pos:{
                            label: "Boca",
                            placeholder: "Seleccione una Boca ...",
                            required: false,
                            visibility: true
                        },
                        avatar:{
                            label: "",
                            placeholder: "",
                            required: false,
                            visibility: true
                        },
                        name: {
                            label: "Nombre Completo",
                            placeholder: "Nombre completo del usuario",
                            required: true,
                            visibility: true
                        },
                        last_name: {
                            label: "Apellido",
                            placeholder: "Apellido completo del usuario",
                            required: true,
                            visibility: true
                        },
                        area:{
                            label: "Área",
                            placeholder: "",
                            required: true,
                            visibility: true
                        },
                        birthday:{
                            label: "Fecha de Nacimiento",
                            placeholder: "",
                            required: false,
                            visibility: true
                        },
                        city:{
                            label: "Ciudad",
                            placeholder: "",
                            required: false,
                            visibility: true
                        },
                        country:{
                            label: "País",
                            placeholder: "",
                            required: true,
                            visibility: true
                        },
                        email:{
                            label: "Email",
                            placeholder: "karla.castro@dots.com",
                            required: true,
                            visibility: true
                        },
                        gender:{
                            label: "Género",
                            placeholder: "",
                            required: false,
                            visibility: true
                        },
                        language:{
                            label: "Lengua",
                            placeholder: "",
                            required: true,
                            visibility: true
                        },
                        phone:{
                            label: "Teléfono",
                            placeholder: "(011) 1147999999",
                            required: false,
                            visibility: true
                        },
                        prefix:{
                            label: "Prefijo",
                            placeholder: "",
                            required: false,
                            visibility: true
                        },
                        role:{
                            label: "Rol",
                            placeholder: "",
                            required: true,
                            visibility: true
                        },
                        region:{
                            label: "Region",
                            placeholder: "",
                            required: true,
                            visibility: true
                        },
                        time_zone:{
                            label: "Zona Horaria",
                            placeholder: "",
                            required: true,
                            visibility: true
                        },
                        dni:{
                            label: "DNI",
                            placeholder: "Ingresar DNI",
                            required: false,
                            visibility: true
                        },
                        user_login:{
                            label: "ID único",
                            placeholder: "Agregar identificación única...",
                            required: true,
                            visibility: true
                        },
                        test_user:{
                            label: "Usuario de prueba",
                            placeholder: "",
                            required: false,
                            visibility: true
                        },
                        can_use_panel:{
                            label: "Acceso al panel",
                            placeholder: "",
                            required: false,
                            visibility: true
                        },
                        additional:{
                            label: "",
                            placeholder: "",
                            required: false,
                            visibility: true
                        }
                    },
                    actions: ["read", "create", "update", "delete"],
                    section_list_filters: [ { id: "role",  label:"Rol" } ]
                },
                store: {}
            }
            
            

            const courses_arr = path(['data', 'single', 'courses'], res) ? changeTitleToName(path(['data', 'single', 'courses'], res)) : [];
            const content_arr = path(['data', 'single', 'content'], res) ? changeTitleToName(path(['data', 'single', 'content'], res)) : [];
            const activities_arr = path(['data', 'single', 'activities'], res) ? normalizaActivities(path(['data', 'single', 'activities'], res)) : [];
            
            let countries_reports_arr = [];
            const countries_reports = path(['data', 'single', 'user_countries'], res) ? path(['data', 'single', 'user_countries'], res) : [];
            if(countries_reports){
                countries_reports_arr = countries_reports.map((el, index) => {
                    return {_id: el.id_country, name: el.name}
                })
            }
            
            setBasicData({profiles: path(['data', 'single', 'userProfiles'], res) ? path(['data', 'single', 'userProfiles'], res) : [],
                          countries: path(['data', 'single', 'countries'], res) ? path(['data', 'single', 'countries'], res) : [],
                          clusters: path(['data', 'single', 'cluster'], res) ? path(['data', 'single', 'cluster'], res) : [],
                          departments: path(['data', 'single', 'departments'], res) ? path(['data', 'single', 'departments'], res) : [],
                          formats: path(['data', 'single', 'formats'], res) ? path(['data', 'single', 'formats'], res) : [],
                          retailers: path(['data', 'single', 'retailers'], res) ? path(['data', 'single', 'retailers'], res) : [],
                          idioms: path(['data', 'single', 'idioms'], res) ? path(['data', 'single', 'idioms'], res) : [],
                          areas: path(['data', 'single', 'areas'], res) ? path(['data', 'single', 'areas'], res) : [],
                          roles: path(['data', 'single', 'roles'], res) ? path(['data', 'single', 'roles'], res) : [],
                          language: lenguages_arr,
                          prefix: path(['data', 'single', 'prefijo'], res) ? path(['data', 'single', 'prefijo'], res) : [],
                          gender: path(['data', 'single', 'gender'], res) ? path(['data', 'single', 'gender'], res) : [],
                          time_zone: path(['data', 'single', 'time_zone'], res) ? path(['data', 'single', 'time_zone'], res) : [],
                          user_profiles: path(['data', 'single', 'userProfiles'], res) ? path(['data', 'single', 'userProfiles'], res) : [],
                          questions_type: path(['data', 'single', 'questions_type'], res) ? path(['data', 'single', 'questions_type'], res) : [],
                          assessment_type: path(['data', 'single', 'assessment_type'], res) ? path(['data', 'single', 'assessment_type'], res) : [],
                          value_points: path(['data', 'single', 'value_points'], res) ? path(['data', 'single', 'value_points'], res) : [],
                          regions: path(['data', 'single', 'region'], res) ? path(['data', 'single', 'region'], res) : [],
                          urls: path(['data', 'single', 'urls'], res) ? path(['data', 'single', 'urls'], res) : [],
                          flags: path(['data', 'single', 'flag'], res) ? path(['data', 'single', 'flag'], res) : [],
                          labels: path(['data', 'single', 'labels'], res) ? path(['data', 'single', 'labels'], res) : [],
                          labels_courses: path(['data', 'single', 'labels_courses'], res) ? path(['data', 'single', 'labels_courses'], res) : [],
                          labels_benefits: path(['data', 'single', 'labels_benefit'], res) ? path(['data', 'single', 'labels_benefit'], res) : [],
                          benefit_types: path(['data', 'single', 'benefit_types'], res) ? path(['data', 'single', 'benefit_types'], res) : [],
                          labels_ecommerce: path(['data', 'single', 'labels_ecommerce'], res) ? path(['data', 'single', 'labels_ecommerce'], res) : [],
                          brand: path(['data', 'single', 'brand'], res) ? path(['data', 'single', 'brand'], res) : [],
                          campaigns: path(['data', 'single', 'campaigns'], res) ? path(['data', 'single', 'campaigns'], res) : [],
                          achievements: achievements_arr,
                          courses: courses_arr,
                          content: content_arr,
                          type_goto: type_goto_arr,
                          valoresUTC: valoresUTC_arr,
                          activities: activities_arr,
                          user_countries: path(['data', 'single', 'user_countries'], res) ? path(['data', 'single', 'user_countries'], res) : [],
                          country: path(['data', 'single', 'countrie'], res) ? path(['data', 'single', 'countrie'], res) : [],
                          //settings: settings,
                          settings: path(['data', 'single', 'menu'], res) ? path(['data', 'single', 'menu'], res) : [],
                          //benefit_types: benefit_types,
                          link_destination_type: link_destination_type,
                          validate_user_options: validate_user_options,
                          countries_reports: countries_reports_arr,
                          user_id: path(['data', 'single', 'user_id'], res) ? path(['data', 'single', 'user_id'], res) : "",
                          user_language: path(['data', 'single', 'user_language'], res) ? path(['data', 'single', 'user_language'], res) : ""});
        return true;

	}, []);

    const normalizaActivities = (arr) => {
        
        for (var i = 0; i < arr.length; i++) {
            arr[i].name = arr[i].name_activity;
            delete arr[i].name_activity;
        }
        return arr;

    }

    const changeTitleToName = (arr) => {
        
        for (var i = 0; i < arr.length; i++) {
            arr[i].name = arr[i].title;
            delete arr[i].title;
        }
        return arr;

    }
    
    const userLoginValidate = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/user_login_valid`;
        try {
            
            const result = await connect.get(requestURL, {
                headers: {
                    'user_login': payload.user_login,
                },
            });

            return result;
    
    
        } catch (error) {
        }

	};
    
    const getEcommerceNewCategory = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/ecommerce_categories`;
        const result = connect.get(`${requestURL}`)
        return result;

	}, []);

    const getEcommerceCategories  = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/ecommerce_categories`;
        connect.get(`${requestURL}`, {
            headers: {
                // 'page': page,
                // 'size': rowPerPage,
            },
        }).then(res => {
            
            setEcommerceCategories(path(['data', 'single', 'categories'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const getEcommerceProducts = useCallback(async (page = 0, rowPerPage = 25, filters) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/ecommerce_products`;
        connect.get(`${requestURL}`, {
            headers: {
                'page': page,
                'size': rowPerPage,
                'category': filters.category,
                'date_order': parseInt(filters.date_order),
                'text_filter': filters.text_filter
            },
        }).then(res => {
            
            setProducts(res.data.single);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const getEcommerceProductsSearch = useCallback(async (page = 0, rowPerPage = 25, input) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/ecommerce/search_product`;
        const response = connect.get(`${requestURL}`, {
            headers: {
                'page': page,
                'size': rowPerPage,
                'input': ""
            }})

        return response;

	}, []);
    
    const getProduct = useCallback(async (id) => {


        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_product`;
        connect.get(`${requestURL}`, {
            headers: {
                'product_id': id,
            },
        }).then(res => {
            
            setProduct(path(['data', 'single', 'product'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const editProduct = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_product`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const statusProduct = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_product`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const createProduct = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/created_product`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const getBenefits = useCallback(async (page = 0, rowPerPage = 25, filters) => {

        let header_list = {'page': page,
                           'size': rowPerPage,
                           'date_order': parseInt(filters.date_order),
                           'label': filters.label,
                           'text_filter': filters.text_filter}

        if(filters.status != 'all'){
            header_list['filter'] = JSON.stringify([{"$or":[{"status" : parseInt(filters.status)}]}]);
        }

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/benefits`;
        connect.get(`${requestURL}`, {
            headers: header_list,
        }).then(res => {
            
            setBenefits(res.data.single);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const getBenefit = useCallback(async (id) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_benefit`;
        connect.get(`${requestURL}`, {
            headers: {
                'id_benefit': id,
            },
        }).then(res => {
            
            setBenefit(path(['data', 'single', 'benefit'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const editBenefit = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/benefit_edit`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };

    const createBenefit = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/benefits_created`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const statusBenefit = async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/change_status_benefit_voucher`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };

    const statusBenefitRedeem = async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/updated_status_benefit`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };
    
    const getAudit = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/stores/get_audit_moderation`;
        connect.get(`${requestURL}`, {
            headers: {
                audit_id: payload.audit_id,
            },
        }).then(res => {
            
            setAudit(res.data.single);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);


    const saveModeration = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/stores/moderation`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const getCampaigns = useCallback(async (page = 0, rowPerPage = 25, filters) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/stores/get_forms_mederation`;
        connect.get(`${requestURL}`, {
            headers: {
                campaign_id: filters.campaign_id,//"663a879e8d51be6d1170d7aa",
                filter: filters.status.length ? JSON.stringify([{"status": parseInt(filters.status)}]) : "",//parseInt(filters.status) === -2 ? JSON.stringify([{"$or":[{"status" : -2}]}]) : JSON.stringify([{"$or":[{"status" : 0} , {"status" : 1}]}]),
                page: page,
                size: rowPerPage,
                area: filters.area,
                activity_id: filters.activity_id,
                text_filter: filters.text_filter
            },//header_list,
        }).then(res => {
            
            setAudits(res.data.single);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);
    

    const getBenefitRedeems = useCallback(async (page = 0, rowPerPage = 25, filters) => {

        let header_list = {'page': page,
                           'size': rowPerPage,
                           'date_order': parseInt(filters.date_order),
                           'text_filter': filters.text_filter}

        if(filters.status != 'all'){
            header_list['filter'] = JSON.stringify([{"$or":[{"status" : parseInt(filters.status)}]}]);
        }


        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/benefits_redeems`;
        connect.get(`${requestURL}`, {
            headers: header_list,
        }).then(res => {
            
            setBenefitRedeems(res.data.single);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);
    
    

    const getCourses = useCallback(async (page = 0, rowPerPage = 25, filters) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/courses`;
        connect.get(`${requestURL}`, {
            headers: {
                'page': page,
                'size': rowPerPage,
                'filter': parseInt(filters.status) === -2 ? JSON.stringify([{"$or":[{"status" : -2}]}]) : JSON.stringify([{"$or":[{"status" : 1}]}]),
                'alphabetical_order': parseInt(filters.alphabetical_order),
                'date_order': parseInt(filters.date_order),
                'label': filters.label,
                'text_filter': filters.text_filter
            },
        }).then(res => {
            
            setCourses(res.data.single);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const getCourse = useCallback(async (id) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/course`;
        connect.get(`${requestURL}`, {
            headers: {
                'id_course': id,
            },
        }).then(res => {
            
            setCourse(path(['data', 'single', 'course'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);
    
    
    const getSurvey = useCallback(async (id) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_survey`;
        connect.get(`${requestURL}`, {
            headers: {
                'survey_id': id,
            },
        }).then(res => {
            setSurvey(path(['data', 'single', 'survey'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

    }, []);

    const getSurveys = useCallback(async (page = 0, rowPerPage = 25, filters) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_surveys`;
        connect.get(`${requestURL}`, {
            headers: {
                'page': page,
                'size': rowPerPage,
                'date_order': parseInt(filters.date_order),
                'text_filter': filters.text_filter
            },
        }).then(res => {
            
            setSurveys(res.data.single);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const getSurveyToAsign  = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_survey_to_asign`;
        connect.get(`${requestURL}`, {
            headers: {},
        }).then(res => {
            setSurverList(path(['data', 'single', 'surveys'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);


    
    const editSurvey = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_survey`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const createSurvey = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/created_survey`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const statusSurvey = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_survey`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const getAssessment = useCallback(async (id) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_assessment`;
        connect.get(`${requestURL}`, {
            headers: {
                'id_assessment': id,
            },
        }).then(res => {
            setAssessment(path(['data', 'single', 'assessment'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

    }, []);

    const createCreatedAssessment = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/created_assessment`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const editCreatedAssessment = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_assessment`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const deleteAssessment = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_assessment`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    }, []);

    const getPosts = useCallback(async (page = 0, rowPerPage = 25, filters) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_posts`;
        connect.get(`${requestURL}`, {
            headers: {
                'page': page,
                'size': rowPerPage,
                'filter': JSON.stringify([{"$or":[{"status" : parseInt(filters.status)}]}]),
                'alphabetical_order': parseInt(filters.alphabetical_order),
                'date_order': parseInt(filters.date_order),
                'text_filter': filters.text_filter
            },
        }).then(res => {
            
            setPosts(res.data.single);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const getPost = useCallback(async (id) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_post`;
        connect.get(`${requestURL}`, {
            headers: {
                'post_id': id,
            },
        }).then(res => {

            setPost(path(['data', 'single', 'post'], res));

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

    }, []);
    
    const createPost = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/create_post`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await connect.post(requestURL, params, config);
        return result;

    }, []);

    const editPost = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/post_edit`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };
    
    const getDataGoTo = useCallback(async (path) => {
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_data_go_to`;
        return connect.get(`${requestURL}`, {
            headers: {
                'path': path,
            },
        });


    }, []);

    const createLabel = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/created_label`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };

    const createCategory = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/created_categorie`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };

    const editCategory = async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_category`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };

    const statusCategory = async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/edit_category`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };

    const resetPasword = async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/reset_pasword`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    };

    
    
    const getPosToAsign  = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/pos/get_pos_to_asign`;
        connect.get(`${requestURL}`, {
            headers: {
                'page': 0,
                'size': 1500,
            },
        }).then(res => {
            const pos_list = path(['data', 'single', 'pos'], res);
            const pos_list_updated = pos_list.map((pos, index) => {
                return {_id: pos._id, name: pos.code +" - "+ pos.name};
            })
            setPosList(pos_list_updated);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const sendComment = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/comment`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    }, []);

    const getPostsComments = useCallback(async (page = 0, rowPerPage = 25, filters) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/get_all_comments`;
        connect.get(`${requestURL}`, {
            headers: {
                //campaign_id: filters.campaign_id,//"663a879e8d51be6d1170d7aa",
                page: page,
                size: rowPerPage,
                //'filter': JSON.stringify([{"$or":[{"status" : parseInt(filters.status)}]}]),
                //'alphabetical_order': parseInt(filters.alphabetical_order),
                post_id: filters.post_id,
                date_order: parseInt(filters.date_order),
                text_filter: filters.text_filter
            },//header_list,
        }).then(res => {
            
            setPostsComments(res.data.single);

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

	}, []);

    const getPostComment = useCallback(async (comment_id) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/comment`;
        const result = connect.get(`${requestURL}`, {
            headers: {
                comment_id: comment_id,
            },
        }).then(res => {
            return res.data.single;

        }).catch(err => {

            setHaveResponseError(true);
            setHaveResponseErrorMessage(err.message);

        });

        return result;

	}, []);

    

    const statusComments = async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/delete_comment`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const result = await connect.post(requestURL, params, config);

        return result;

    }

    const posts_survey = useCallback(async (post_id = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/export/posts_survey`;
        const result = connect.get(`${requestURL}`, {
            headers: {
                'post_id': post_id ? post_id : "",
            },
        });
        return result;

    }, []);

    const exports_bq = useCallback(async (code, date_init, date_finish, country = '') => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/exports_bq`;
        const result = connect.get(`${requestURL}`, {
            headers: {
                'code': code,
                'date_init': date_init,
                'date_finish': date_finish,
                'country': country
            },
        });
        return result;

    }, []);
    
    

    return (
        <ContentContext.Provider 
            value={{
                getFormsData,
                exports_bq,
                posts_survey,
                testEndpoint,
                statusComments,
                sendComment,
                getPostComment,
                getPostsComments,
                getUser,
                getUsers,
                getStore,
                getStores,
                getUserDevices,
                getByPassImageUploadRoute,
                getCourses,
                getCourse,
                getBenefits,
                getBenefit,
                getAudit,
                getCampaigns,
                getBenefitRedeems, 
                getEcommerceProducts,
                getEcommerceCategories,
                getEcommerceNewCategory,
                getEcommerceProductsSearch,
                getProduct,
                statusBenefit,
                statusBenefitRedeem,
                editBenefit,
                createBenefit,
                getAssessment,
                getModule,
                getPosts,
                getPost,
                editPost,
                createPost,
                editStore,
                createStore,
                statusStores,
                uploadFile,
                uploadRawFile,
                editUser,
                editModule,
                statusUser,
                deleteUser,
                editUserProfile,
                deleteAssessment,
                statusCourse,
                createUser,
                createCourse,
                editCourse,
                editProduct,
                createProduct,
                statusProduct,
                createModule,
                statusModule,
                userLoginValidate,
                createCreatedAssessment,
                editCreatedAssessment,
                editCategory,
                statusCategory,
                createCategory,
                statusPost,
                createSurvey,
                editSurvey,
                getSurveyToAsign,
                statusSurvey,
                surverList, 
                setSurverList,
                getSurvey,
                getSurveys,
                getPosToAsign,
                posList, setPosList,
                users, setUsers,
                user, setUser,
                stores, setStores, 
                store, setStore,
                courses, setCourses, 
                course, setCourse,
                benefits, setBenefits, 
                benefit, setBenefit,
                benefitRedeems, setBenefitRedeems,
                product, setProduct,
                products, setProducts,
                devices, setDevices, 
                assessment, setAssessment,
                module, setModule,
                post, setPost,
                posts, setPosts,
                idModuleSelected, setIdModuleSelected,
                idCourseSelected, setIdCourseSelected,
                basicData, setBasicData,
                ecommerceCategories, setEcommerceCategories,
                surveys, setSurveys,
                survey, setSurvey,
                getDataGoTo,
                createLabel,
                resetPasword,
                audits,
                setAudits,
                audit,
                setAudit,
                saveModeration,
                postsComments,
                setPostsComments,
                getFilterData,
                menuData, setMenuData
            }}>
            {children}
        </ContentContext.Provider>
    );
};
