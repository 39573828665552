import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import LoaderComponent from '../../../components/elements/LoaderComponent';
import IconCustom from '../../../components/elements/IconCustom'; 
import DeleteElement from "../../../components/elements/Dialogs/DeleteElement";
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';
import { BiSolidDownArrow } from "react-icons/bi";

import { ContentContext,
         ConfigurationContext } from '../../../context';

import { EditIcon,
         FormatListNumberedIcon,
         CountIcon,
         TaskIcon,
         TrashIcon,
         CoursesMenuIcon,
         DangerousIcon,
         DoneOutlineIcon,
         ViewHeadlineIcon,
         RuleIcon,
         BorderAllIcon,
         PanoramaIcon,
         SegmentarionIcon,
         LanguageIcon } from '../../../helpers/icons';

import { getQuestionTypeLabel,
         getQuestionTypeTitle,
         getNameLanguage } from '../../../helpers/functions';

import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;

    .content_data{
        display: flex;
        flex-direction: column;
    }
    .content_data.show{
        display: flex;
        padding: 30px;
        div:first-child{
            margin-top: 0!important;
        }
    }
    .content_data.hide{
        display: none;
    }
    .box_lang{
        &.error{
            border: 1px solid ${props => `rgb(${props.pancolors.red})`}!important;

            .head_form_lang{
                background-color: ${props => `rgb(${props.pancolors.light_white})`};
                >.title{
                    .icon{
                        svg{
                            fill: ${props => `rgb(${props.pancolors.red})`}!important;
                        }
                    }
                    .text{
                        color: ${props => `rgb(${props.pancolors.red})`}!important;
                    }
                }
            }
        }
        &.multilang{
            box-sizing: border-box;
            padding: 0px 0px 0px 0px;
            /* background-color: ${props => `rgb(${props.pancolors.light_white})`}; */
            border: 5px solid ${props => `rgb(${props.pancolors.light_white})`};
            margin-bottom: 20px;
            &.open{
                .head_form_lang{
                    border-bottom: 5px solid ${props => `rgb(${props.pancolors.light_white})`};
                }
                /* padding: 20px 30px 50px 30px; */
            }
            .head_form_lang{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 20px 30px 20px 30px;
                background-color: ${props => `rgb(${props.pancolors.light_white})`};
                >.title{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .icon{
                        svg{
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .text{
                        font-size: 22px;
                        line-height: 22px;
                        font-weight: 700;
                        color: ${props => `rgb(${props.pancolors.dots})`};
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .actions{
        display: flex;
        flex-direction: row;
        .bt_evaluation_delete,
        .bt_segmentation{
            margin-left: 10px;
        }
        .bt_evaluation_edit{

        }
    }
    .loader{
        margin-top: 30px;
    }
    .box_evaluation_container{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 40px;
        >.title{
            color: ${props => `rgb(${props.pancolors.black})`};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .evaluation_data{
            display: grid; 
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            width: 100%;
            margin-bottom: 30px;
            .element{
                display: flex;
                flex-direction: row;
                align-items: center;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
                font-size: 16px;
                .icon{
                    display: flex;
                    svg{
                        fill: ${props => `rgb(${props.pancolors.black_overwhite})`};  
                    }
                }
                .text{
                    font-weight: 600;
                    margin-left: 5px;
                }
                .value{
                    margin-left: 5px;
                }
            }

        }

        .evaluation_questions{
            margin-top: 30px;

            .question{
                display: flex;
                flex-direction: column;
                margin-bottom: 50px;
                .number{
                    font-size: 21px;
                    line-height: 21px;
                    font-weight: 600;
                    margin-bottom: 15px;
                }
                .data{
                    display: flex;
                    flex-direction: column;
                    
                    .single,
                    .double{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-between;
                        margin-bottom: 7px;
                        .item{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            
                            .icon{

                            } 
                            .name{
                                font-size: 16px;
                                font-weight: 600;
                                color: ${props => `rgb(${props.pancolors.black_overwhite})`};
                            }
                            .value{
                                font-size: 16px;
                                color: ${props => `rgb(${props.pancolors.dark_gray})`};
                                margin-left: 5px;
                            }
                        }
                    }
                    .single{
                        .item{
                            width: 100%;
                        }
                        &.with_image{
                            flex-direction: column;
                            .image{
                                width: 250px;
                                margin-top: 10px;
                                img{
                                    float: left;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .double{
                        .item{
                            width: 50%;
                        }
                    }
                }
                .anwsers{
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    &.single_choice_image,
                    &.multiple_choice_image{
                        flex-direction: row;
                    }
                    &.comments{
                        margin-top: 0;
                        .icon{
                            margin-left: 0!important;
                        }
                    }
                    .item{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 5px;
                        .tag{
                            background-color: ${props => `rgb(${props.pancolors.light_white})`};
                            border-radius: 6px;
                            border: 1px solid ${props => `rgb(${props.pancolors.light_gray})`};
                            box-sizing: border-box;
                            padding: 8px 15px;
                            margin-left: 7px;
                        }
                        &.single_choice_image,
                        &.multiple_choice_image{
                            margin-right: 30px;
                            flex-direction: column;
                            .top_image{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: flex-start;
                                width: 100%;
                                margin-bottom: 7px;
                            }
                            .bottom_image{
                                width: 100%;
                                margin-top: 7px;
                                text-align: center;
                            }
                            >.image{
                                width: 250px;
                                img{
                                    float: left;
                                    width: 100%;
                                }
                            }
                            :last-child{
                                margin-right: 5px;
                            }
                        }
                        :last-child{
                            margin-bottom: 0;
                        }
                        .defered{
                            box-sizing: border-box;
                            background-color: ${props => `rgb(${props.pancolors.light_white})`};
                            padding: 10px 15px;
                            >.value{
                                font-weight: 600;
                            }
                        }
                        .icon{
                            margin-left: 5px;
                        }
                        .name{
                            font-size: 16px;
                            font-weight: 600;
                            color: ${props => `rgb(${props.pancolors.black_overwhite})`};
                        }
                        .value{
                            font-size: 16px;
                            color: ${props => `rgb(${props.pancolors.dark_gray})`};
                            margin-left: 5px;
                        }
                    }
                }
            }
        }

        

    }

`


const ViewContainer = (props) => {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const { id_assessment, id_course, id_module } = useParams();
    
    const { assessment,
            setAssessment,
            getAssessment,
            idCourseSelected,
            setIdCourseSelected,
            setIdModuleSelected,
            basicData } = useContext(ContentContext);
    
    const { panColors,
            userCountrySelected } = useContext(ConfigurationContext);
    

    const [ isDeleted, setIsDeleted ] = useState(false);
    const [ titleDeleteText, setTitleDeleteText ] = useState("");
    const [ deleteElementOpen, setDeleteElementOpen ] = useState(false);
    const [ elementToDelete, setElementToDelete ] = useState("");
    const [ showLanguage, setShowLanguage ] = useState(null);
    
    const handleEvaluationEdit = () => {

        if(id_course){
            setIdCourseSelected(id_course);
            navigate(`/course/${id_course}/evaluation/${id_assessment}/edit`);
        }
        if(id_module){
            setIdModuleSelected(id_module);
            navigate(`/module/${id_module}/evaluation/${id_assessment}/edit`);
        }
    }
    
    const handleDelete = (id) => {
        setElementToDelete(id);
        setTitleDeleteText(`<span class="bolder">${t("texts.Confirmar Eliminar la actual evaluacion")}</span>`);
        setDeleteElementOpen(true);
    }
    
    const handleSegmentacion = () => {
        navigate(`/evaluation/${id_assessment}/segmentation/edit`);
    }

    const handleShowLanguage = (lang) => {
        if(lang !== showLanguage){
            setShowLanguage(lang);
        }else{
            setShowLanguage(null);
        }
    }

    useEffect(() => {
        if(id_assessment){
            getAssessment(id_assessment);
        }

        return () => {}
    }, [id_assessment])

    useEffect(() => {
        if(isDeleted){
            if(id_course){
                navigate(`/courses`);
            }
            if(id_module){
                navigate(`/course/${idCourseSelected}/modules`);
            }
        }
        return () => {}
    }, [isDeleted])

    useEffect(() => {
      return () => {
        //setAssessment(null);
      }
    }, [])

    useEffect(() => {
            if(userCountrySelected){
                if(userCountrySelected.languages.length){
                    //setSelectedLang(userCountrySelected.languages[0].language_identifier);
                    setShowLanguage(userCountrySelected.languages[0].language_identifier);
                }
            }
        return () => {}
        }, [userCountrySelected])
    
    if(!assessment || !Object.keys(basicData).length){
        return (
            <div className='loader'>
                <LoaderComponent />
            </div>
        );
    }

    return (
        <WrapperStyled
            pancolors={panColors}
            className="dashboard"
        >

            <DeleteElement 
                open={deleteElementOpen} 
                setOpen={setDeleteElementOpen} 
                title={titleDeleteText}
                setTitle={setTitleDeleteText}
                id={elementToDelete}
                scope="assessment"
                isDeleted={isDeleted}
                setIsDeleted={setIsDeleted}
            />

            <div className="actions">

                <ButtonCustom 
                    className="bt_evaluation_edit"
                    text={t("texts.Editar Evaluacion")}
                    onClick={handleEvaluationEdit}
                    style_type="invert"

                    icon={<EditIcon />}
                    background={{ normal: panColors.primary, hover: panColors.white }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                />

                <ButtonCustom 
                    className="bt_segmentation"
                    text={t("texts.Segmentacion")}
                    onClick={handleSegmentacion}
                    style_type="invert"

                    icon={<SegmentarionIcon />}
                    background={{ normal: panColors.primary, hover: panColors.white }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                />

                <ButtonCustom 
                    className="bt_evaluation_delete"
                    text={t("texts.Eliminar Evaluacion")}
                    onClick={() => handleDelete(id_assessment)}
                    style_type="invert"

                    icon={<TrashIcon />}
                    background={{ normal: panColors.white, hover: panColors.red }}
                    border={{ normal: panColors.red, hover: panColors.red }}
                    textcolor={{ normal: panColors.red, hover: panColors.white }}
                />

            </div>






            <div className="box_evaluation_container">
                <div className="title">
                    {t("texts.Datos de la evaluacion")}
                </div>

                <div className="evaluation_data">
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<CoursesMenuIcon />} />
                        </div>
                        <div className="text">{t("texts.Cantidad de preguntas a evaluar")}:</div>
                        <div className="value">{assessment.count_questions}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<CountIcon />} />
                        </div>
                        <div className="text">{t("texts.Cantidad de preguntas")}:</div>
                        <div className="value">{assessment.questions.length}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<FormatListNumberedIcon />} />
                        </div>
                        <div className="text">{t("texts.Puede repetir indefinidamente")}:</div>
                        <div className="value">{assessment.can_repeat ? t("texts.Si") : t("texts.No")}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<FormatListNumberedIcon />} />
                        </div>
                        <div className="text">{t("texts.Puede repetir despues de responder mal")}:</div>
                        <div className="value">{assessment.can_repeat_failure ? t("texts.Si") : t("texts.No")}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<FormatListNumberedIcon />} />
                        </div>
                        <div className="text">{t("texts.Puede repetir despues de responder bien")}:</div>
                        <div className="value">{assessment.can_repeat_success ? t("texts.Si") : t("texts.No")}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<FormatListNumberedIcon />} />
                        </div>
                        <div className="text">{t("texts.Preguntas y opciones aleatorias")}:</div>
                        <div className="value">{assessment.random ? t("texts.Si") : t("texts.No")}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<TaskIcon />} />
                        </div>
                        <div className="text">{t("texts.Score minimo para aprobar evaluacion")}:</div>
                        <div className="value">{assessment?.min_approval ? assessment.min_approval + " pts" : "- - -"}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<TaskIcon />} />
                        </div>
                        <div className="text">{t("texts.Aprobar esta evaluacion suma")}:</div>
                        <div className="value">{assessment.points_success} pts</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<TaskIcon />} />
                        </div>
                        <div className="text">{t("texts.Desaprobar esta evaluacion resta")}:</div>
                        <div className="value">{assessment.points_failure} pts</div>
                    </div>
                </div>


                {userCountrySelected?.languages.map((lang, index) => {
                        return(
                            <div className={`box_lang ${ userCountrySelected?.languages?.length > 1 ? " multilang" : ""}${showLanguage === lang.language_identifier ? ' open' : ''}`}>
                                {userCountrySelected?.languages?.length > 1 &&
                                <div className="head_form_lang">
                                    <div className="title">
                                        <div className="icon">
                                            <LanguageIcon />
                                        </div>
                                        <div className="text">
                                            {t("texts.Post en")} { getNameLanguage(userCountrySelected?.languages, lang.language_identifier) }
                                        </div>
                                    </div>
                                    {userCountrySelected?.languages.length ?
                                    <ButtonTextCustom 
                                        className="bt_show_more_info"
                                        text={`${showLanguage === lang.language_identifier ? t("texts.Cerrar") : t("texts.Abrir")}`}
                                        onClick={() => handleShowLanguage(lang.language_identifier)}

                                        icon={<BiSolidDownArrow />}
                                        background={{ normal: "", hover: "" }}
                                        border={{ normal: "", hover: "" }}
                                        textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                                    /> : "" }
                                
                                </div>
                                }
                                <div className={`content_data${showLanguage === lang.language_identifier ? ' show' : ' hide'}`}>
                                    
                                    




                                    <div className='evaluation_questions'>
                            
                                        {assessment?.translations[showLanguage]?.questions.map((question, index) => {
                                            console.log("question", question)
                                            //translations[showLanguage]
                                            let type_title = getQuestionTypeTitle(basicData.questions_type, question.input_type);
                                            return (
                                                <div className="question">
                                                    <div className="number">{t("texts.Pregunta")} {index+1}</div>
                                                    <div className="data">
                                                        <div className='single'>
                                                            <div className='item'>
                                                                <div className='icon'><IconCustom icon={<BorderAllIcon />} /></div>
                                                                <div className='name'>{t("texts.Tipo de Pregunta")}:</div>
                                                                <div className='value'>{getQuestionTypeLabel(basicData.questions_type, question.input_type) }</div>
                                                            </div>
                                                        </div>
                                                        <div className='double'>
                                                            <div className='item'>
                                                                <div className='icon'><IconCustom icon={<RuleIcon />} /></div>
                                                                <div className='name'>{t("texts.Score por opcion correcta")}:</div>
                                                                <div className='value'>{question.score} pts</div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className='icon'><IconCustom icon={<RuleIcon />} /></div>
                                                                <div className='name'>{t("texts.Score por opcion incorrecta")}:</div>
                                                                <div className='value'>{question.penalty_score} pts</div>
                                                            </div>
                                                        </div>
                                                        <div className='single'>
                                                            <div className='item'>
                                                                <div className='icon'><IconCustom icon={<ViewHeadlineIcon />} /></div>
                                                                <div className='name'>{t("texts.Pregunta")}:</div>
                                                                <div className='value'>{question.title}</div>
                                                            </div>
                                                        </div>
                                                        {question.media ?
                                                        <div className='single with_image'>
                                                            <div className='item'>
                                                                <div className='icon'><IconCustom icon={<PanoramaIcon />} /></div>
                                                                <div className='name'>{t("texts.Imagen que acompaña")}:</div>
                                                            </div>
                                                            <div className='image'>
                                                                <img src={question.media} />
                                                            </div>
                                                        </div> : ""}
                                                        
                                                    </div>
                                                    
                                                    {type_title !== "comments" ? 
                                                    <div className={`anwsers ${type_title}`}>
                                                        {question.secondary_actions.length && question.secondary_actions.map((secondary, index) => {
                                                            return(
                                                                <>
                                                                {type_title === "single_choice" || type_title === "multiple_choice" ? 
                                                                    <div className={`item ${type_title}`}>
                                                                        { secondary.go_to ? <div className='defered'>{t("texts.Continua a pregunta")}: <span className='value'>2</span></div> : "" }
                                                                        <div className='icon'>
                                                                            {secondary.correct ?
                                                                                <IconCustom icon={<DoneOutlineIcon />} />
                                                                            :
                                                                                <IconCustom icon={<DangerousIcon />} />
                                                                            }
                                                                        </div>
                                                                        <div className='name'>{secondary.correct ? t("texts.Correcta") : t("texts.Incorrecta")}:</div>
                                                                        <div className='value'>{secondary.label}</div>
                                                                    </div> : ""
                                                                }
                                                                {type_title === "single_choice_image" || type_title === "multiple_choice_image" ? 
                                                                    <div className={`item ${type_title}`}>
                                                                        <div className='top_image'>
                                                                            <div className='icon'>
                                                                                {secondary.correct ?
                                                                                    <IconCustom icon={<DoneOutlineIcon />} />
                                                                                :
                                                                                    <IconCustom icon={<DangerousIcon />} />
                                                                                }
                                                                            </div>
                                                                            <div className='name'>{secondary.correct ? t("texts.Correcta") : t("texts.Incorrecta")}:</div>
                                                                        </div>
                                                                        <div className='image'><img src={secondary.media} /></div>
                                                                        { secondary.go_to ? 
                                                                        <div className='bottom_image'>
                                                                            <div className='defered'>{t("texts.Continua a pregunta")}: <span className='value'>{secondary.go_to}</span></div>
                                                                        </div> : "" }

                                                                    </div> : ""
                                                                }
                                                                </>
                                                            )
                                                        })}
                                                    </div> : ""}
                                                    
                                                    {type_title === "comments" ? 
                                                    <div className={`anwsers ${type_title}`}>
                                                        <div className={`item ${type_title}`}>
                                                            <div className='icon'><IconCustom icon={<DoneOutlineIcon />} /></div>
                                                            <div className='name'>{t("texts.Palabras clave para aprobar la pregunta")}:</div>
                                                            {question.secondary_actions.length && question.secondary_actions.map((secondary, index) => {
                                                                return(
                                                                    <div className='tag'>{secondary.label}</div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div> : ""  }

                                                </div>
                                            )
                                        })}
                                    </div>






                                </div>
                            </div>    
                        )
                    })
                }

            </div>













            
                {/* <div className="box_evaluation_container">
                    <div className="title">
                        {t("texts.Datos de la evaluacion")}
                    </div>

                    <div className="evaluation_data">
                        <div className="element">
                            <div className="icon">
                                <IconCustom icon={<CoursesMenuIcon />} />
                            </div>
                            <div className="text">{t("texts.Cantidad de preguntas a evaluar")}:</div>
                            <div className="value">{assessment.count_questions}</div>
                        </div>
                        <div className="element">
                            <div className="icon">
                                <IconCustom icon={<CountIcon />} />
                            </div>
                            <div className="text">{t("texts.Cantidad de preguntas")}:</div>
                            <div className="value">{assessment.questions.length}</div>
                        </div>
                        <div className="element">
                            <div className="icon">
                                <IconCustom icon={<FormatListNumberedIcon />} />
                            </div>
                            <div className="text">{t("texts.Puede repetir indefinidamente")}:</div>
                            <div className="value">{assessment.can_repeat ? t("texts.Si") : t("texts.No")}</div>
                        </div>
                        <div className="element">
                            <div className="icon">
                                <IconCustom icon={<FormatListNumberedIcon />} />
                            </div>
                            <div className="text">{t("texts.Puede repetir despues de responder mal")}:</div>
                            <div className="value">{assessment.can_repeat_failure ? t("texts.Si") : t("texts.No")}</div>
                        </div>
                        <div className="element">
                            <div className="icon">
                                <IconCustom icon={<FormatListNumberedIcon />} />
                            </div>
                            <div className="text">{t("texts.Puede repetir despues de responder bien")}:</div>
                            <div className="value">{assessment.can_repeat_success ? t("texts.Si") : t("texts.No")}</div>
                        </div>
                        <div className="element">
                            <div className="icon">
                                <IconCustom icon={<FormatListNumberedIcon />} />
                            </div>
                            <div className="text">{t("texts.Preguntas y opciones aleatorias")}:</div>
                            <div className="value">{assessment.random ? t("texts.Si") : t("texts.No")}</div>
                        </div>
                        <div className="element">
                            <div className="icon">
                                <IconCustom icon={<TaskIcon />} />
                            </div>
                            <div className="text">{t("texts.Score minimo para aprobar evaluacion")}:</div>
                            <div className="value">{assessment?.min_approval ? assessment.min_approval + " pts" : "- - -"}</div>
                        </div>
                        <div className="element">
                            <div className="icon">
                                <IconCustom icon={<TaskIcon />} />
                            </div>
                            <div className="text">{t("texts.Aprobar esta evaluacion suma")}:</div>
                            <div className="value">{assessment.points_success} pts</div>
                        </div>
                        <div className="element">
                            <div className="icon">
                                <IconCustom icon={<TaskIcon />} />
                            </div>
                            <div className="text">{t("texts.Desaprobar esta evaluacion resta")}:</div>
                            <div className="value">{assessment.points_failure} pts</div>
                        </div>
                    </div>

                    <div className='evaluation_questions'>
                        
                        {assessment.questions.map((question, index) => {
                            let type_title = getQuestionTypeTitle(basicData.questions_type, question.input_type);
                            return (
                                <div className="question">
                                    <div className="number">{t("texts.Pregunta")} {index+1}</div>
                                    <div className="data">
                                        <div className='single'>
                                            <div className='item'>
                                                <div className='icon'><IconCustom icon={<BorderAllIcon />} /></div>
                                                <div className='name'>{t("texts.Tipo de Pregunta")}:</div>
                                                <div className='value'>{getQuestionTypeLabel(basicData.questions_type, question.input_type) }</div>
                                            </div>
                                        </div>
                                        <div className='double'>
                                            <div className='item'>
                                                <div className='icon'><IconCustom icon={<RuleIcon />} /></div>
                                                <div className='name'>{t("texts.Score por opcion correcta")}:</div>
                                                <div className='value'>{question.score} pts</div>
                                            </div>
                                            <div className='item'>
                                                <div className='icon'><IconCustom icon={<RuleIcon />} /></div>
                                                <div className='name'>{t("texts.Score por opcion incorrecta")}:</div>
                                                <div className='value'>{question.penalty_score} pts</div>
                                            </div>
                                        </div>
                                        <div className='single'>
                                            <div className='item'>
                                                <div className='icon'><IconCustom icon={<ViewHeadlineIcon />} /></div>
                                                <div className='name'>{t("texts.Pregunta")}:</div>
                                                <div className='value'>{question.title}</div>
                                            </div>
                                        </div>
                                        {question.media ?
                                        <div className='single with_image'>
                                            <div className='item'>
                                                <div className='icon'><IconCustom icon={<PanoramaIcon />} /></div>
                                                <div className='name'>{t("texts.Imagen que acompaña")}:</div>
                                            </div>
                                            <div className='image'>
                                                <img src={question.media} />
                                            </div>
                                        </div> : ""}
                                        
                                    </div>
                                    
                                    {type_title !== "comments" ? 
                                    <div className={`anwsers ${type_title}`}>
                                        {question.secondary_actions.length && question.secondary_actions.map((secondary, index) => {
                                            return(
                                                <>
                                                {type_title === "single_choice" || type_title === "multiple_choice" ? 
                                                    <div className={`item ${type_title}`}>
                                                        { secondary.go_to ? <div className='defered'>{t("texts.Continua a pregunta")}: <span className='value'>2</span></div> : "" }
                                                        <div className='icon'>
                                                            {secondary.correct ?
                                                                <IconCustom icon={<DoneOutlineIcon />} />
                                                            :
                                                                <IconCustom icon={<DangerousIcon />} />
                                                            }
                                                        </div>
                                                        <div className='name'>{secondary.correct ? t("texts.Correcta") : t("texts.Incorrecta")}:</div>
                                                        <div className='value'>{secondary.label}</div>
                                                    </div> : ""
                                                }
                                                {type_title === "single_choice_image" || type_title === "multiple_choice_image" ? 
                                                    <div className={`item ${type_title}`}>
                                                        <div className='top_image'>
                                                            <div className='icon'>
                                                                {secondary.correct ?
                                                                    <IconCustom icon={<DoneOutlineIcon />} />
                                                                :
                                                                    <IconCustom icon={<DangerousIcon />} />
                                                                }
                                                            </div>
                                                            <div className='name'>{secondary.correct ? t("texts.Correcta") : t("texts.Incorrecta")}:</div>
                                                        </div>
                                                        <div className='image'><img src={secondary.media} /></div>
                                                        { secondary.go_to ? 
                                                        <div className='bottom_image'>
                                                            <div className='defered'>{t("texts.Continua a pregunta")}: <span className='value'>{secondary.go_to}</span></div>
                                                        </div> : "" }

                                                    </div> : ""
                                                }
                                                </>
                                            )
                                        })}
                                    </div> : ""}
                                    
                                    {type_title === "comments" ? 
                                    <div className={`anwsers ${type_title}`}>
                                        <div className={`item ${type_title}`}>
                                            <div className='icon'><IconCustom icon={<DoneOutlineIcon />} /></div>
                                            <div className='name'>{t("texts.Palabras clave para aprobar la pregunta")}:</div>
                                            {question.secondary_actions.length && question.secondary_actions.map((secondary, index) => {
                                                return(
                                                    <div className='tag'>{secondary.label}</div>
                                                )
                                            })}
                                        </div>
                                    </div> : ""  }

                                </div>
                            )
                        })}
                    </div>
                </div> */}

        </WrapperStyled>

    )

}

export default ViewContainer;
