import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import LoaderComponent from '../elements/LoaderComponent';

import { ContentContext,
         ConfigurationContext } from '../../context';
         
import TableCampaigns from './../elements/DataTable/TableCampaigns';
import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`
    background-color: #fff;
`

const CampaignsTable = (props) => {

    const { t } = useTranslation();
    
    const { filters = null } = props;

    const { getCampaigns,
            audits,
            setAudits,
            benefitRedeems, 
            setBenefitRedeems, } = useContext(ContentContext);

    const { panColors } = useContext(ConfigurationContext);

    const headCells = [
        // {
        //     id: 'code',
        //     numeric: false,
        //     disablePadding: false,
        //     label: t("texts.Codigo"),
        //     align: 'left',
        //     sorteable: false,
        // },
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: t("texts.Usuario"),
            align: 'left',
            sorteable: true,
        },
        {
            id: 'user_login',
            numeric: false,
            disablePadding: false,
            label: t("texts.ID usuario"),
            align: 'left',
            sorteable: true,
        },
        // {
        //     id: 'user_name',
        //     numeric: false,
        //     disablePadding: false,
        //     label: t("texts.Tienda"),
        //     align: 'left',
        //     sorteable: true,
        // },
        {
            id: 'points',
            numeric: false,
            disablePadding: false,
            label: t("texts.Formulario"),
            align: 'left',
            sorteable: true,
        },
        // {
        //     id: 'status',
        //     numeric: false,
        //     disablePadding: false,
        //     label: t("texts.Moderacion"),
        //     align: 'left',
        //     sorteable: true,
        // },
        {
            id: 'area',
            numeric: false,
            disablePadding: false,
            label: t("texts.Area"),
            align: 'left',
            sorteable: true,
        },
        {
            id: 'date',
            numeric: false,
            disablePadding: false,
            label: t("texts.Fecha"),
            align: 'left',
            sorteable: true,
        },
        {
            id: '',
            numeric: false,
            disablePadding: false,
            label: t("texts.Acciones"),
            align: 'right',
            sorteable: false,
        }
    ];
    
    useEffect(() => {
        setAudits(null);
        getCampaigns(0, 25, filters);

    }, [filters])

    useEffect(() => {
        return () => {
            setAudits(null);
        }
    }, [])
    
    return (
        <WrapperStyled pancolors={panColors}>
        {audits == null ?
            <LoaderComponent />
        :
        audits?.audits?.length ?
            <TableCampaigns
                headCells={headCells} 
                type={"audits"}
                title={t("texts.Auditorias")} 
                data={audits.audits} 
                getData={getCampaigns}
                totalRows={audits.total}
                filters={filters}
            />
            :
            <>{t("texts.No hay registros para mostrar")}</>
        }
        </WrapperStyled>
    );
}

export default CampaignsTable;